.fileuploader-theme-dragdrop {
  .fileuploader-input {
    display: block;
    padding: 60px 0;
    background: #fff;
    border: 2px dashed #c2cdda;
    border-radius: 3px;
    text-align: center;
    -webkit-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
    .fileuploader-input-inner {
      -webkit-transition: opacity 0.2s ease;
      transition: opacity 0.2s ease;
    }
    &.fileuploader-dragging {
      .fileuploader-input-inner {
        opacity: 0.4;
      }
      img {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
    img {
      display: block;
      max-width: 64px;
      margin: 0 auto;
      margin-bottom: 30px;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    h3 {
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      font-size: 18px;
      font-weight: bold;
      color: #555a6b;
      white-space: normal;
    }
    p {
      color: #74809d;
    }
  }
  &.fileuploader-is-uploading .fileuploader-input-caption:after {
    display: none;
  }
  .fileuploader-pending-loader {
    .right-half, .spinner {
      background: #6381E6;
    }
  }
  .fileuploader-input p {
    margin: 10px 0;
  }
}